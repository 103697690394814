import { render, staticRenderFns } from "./NewSqaComponent.vue?vue&type=template&id=62c11b86&scoped=true"
import script from "./NewSqaComponent.vue?vue&type=script&lang=js"
export * from "./NewSqaComponent.vue?vue&type=script&lang=js"
import style0 from "./NewSqaComponent.vue?vue&type=style&index=0&id=62c11b86&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c11b86",
  null
  
)

export default component.exports