<template>
  <div>
    <h1 class="new_pwdless_main_heading pwdles_pass_main_heading">
      How do you want to log in?
    </h1>
    <div class="new_pwdless_pass_sub_heading pwdles_pass_sub_heading">
      User ID
    </div>
    <div class="new_pwdless_pass_user pwdless_pass_user_gap">
      {{ this.pwdUsername }}
    </div>
    <ul>
      <li
        class="pwdless_options_section"
        v-for="item in pwdAuthOptions"
        :key="item.title"
        id="loginMethodEnabled"
        @click="redirectToMethod(item.pagePath)"
        :class="{ isdisabled: item.disable }"
      >
        <div
          class="pwdless_secure_option pwdless_secure_option_gap"
          v-if="item.available"
        >
          <div class="secure_text" v-if="item.pagePath == 'PwdLessPush'">
            Fast and secure
          </div>
          <div class="pwdless_option">
            <div class="pwdless_option_icon">
              <a>
                <img :src="getIconURL(item.iconName)" :alt="item.iconName" />
              </a>
            </div>
            <div class="pwdless_option_text">
              <a>{{ item.title }}</a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import commonMixin from "@/common/mixin/commonMixins";
import commonErrorMixin from "../common/common-error-mixin";
export default {
  name: "NewOption",
  components: {},
  mixins: [commonMixin, commonErrorMixin],
  data() {
    return {
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
    };
  },
  mounted() {
    setGotoUrlInClientLib(this.getParameterByName("goto"));
    this.gotoUrl = this.$route.query.goto;
    if (this.gotoUrl != null) {
      // determine if there is a goto with a hashtag
      let hashValue = window.location.hash.substr(1);
      if (hashValue != "") {
        let hashInUrl = "#" + window.location.hash.substr(1);
        if (this.gotoUrl != null) {
          this.gotoUrl += hashInUrl;
          this.$store.dispatch("login/updateGotoUrl", this.gotoUrl);
        }
      }
    }
    this.resetState();
    if (iamvar_pwdlessVetLock == true) {
      this.pwdAuthOptions[1].disable = true;
      // if (document.getElementById("IAMerrorMessage")) {
      //   document.getElementById("errorIAMPush").style = "display: flex;";
      //   document.getElementById("IAMerrorMessage").innerHTML =
      //     "<b>You have no remaining verification attempts with phone or email</b><p>Please wait 24 hours to access your account. Use another way to login.";
      // }
    }
    if (this.pwdUsername == null) {
      this.$router.push({ name: "PwdLessLoginRootComponent" });
    }
    document.addEventListener("click", (e) => {
      if (e.target.id == "resendN") {
        this.resendNotification();
      }
    });

    this.changeContextBiocatch("pwdless_login_options_page");
  },
  methods: {
    ...mapMutations("login", ["setNavHistory"]),
    ...mapActions("cvsstepup", ["updateOTPAlwaysOn", "initializeStepup"]),
    ...mapActions("login", [
      "submitPwdAMPasswordForm",
      "submitPwdLessPushLogin",
      "resetState",
      "updateUrlOnMount", // updates urlOnMount on both cvsstepup and login modules
    ]),
    getIconURL(icon) {
      return require(`@/common/assets/images/${icon}.png`);
    },
    resendNotification() {
      this.submitPwdLessPushLogin({ userName: this.pwdUsername });
    },
    redirectToMethod(path) {
      this.clearAllErrors();
      if (path == "PwdLessVerify") {
        this.formData[0].input[0].value = this.pwdUsername;
        this.formData[1].input[0].value = "";
        let payload = {
          formData: this.formData,
        };

        this.submitPwdAMPasswordForm(payload);
      } else if (path == "PwdLessPush") {
        this.submitPwdLessPushLogin({ userName: this.pwdUsername });
      } else if (path == "PwdLessPassword") {
        if (this.gotoUrl) {
          this.$router.push({ name: path, query: { goto: this.gotoUrl } });
        } else this.$router.push({ name: path });
      }
    },
  },
  computed: {
    ...mapGetters("cvsstepup", [
      "flowInitialized",
      "stepupReason",
      "urlOnMount",
      "otpAlwaysOn",
      "dnrStatus",
      "currentStep",
    ]),
    ...mapGetters("login", [
      "gotoUrl",
      "pwdUsername",
      "redirectToStepupPage",
      "submitLoader",
      "pwdAuthOptions",
      "redirectToSecProfilePage",
      "getCurrentComponent",
    ]),
    formData: {
      get: function () {
        return this.$store.getters["login/formData"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateFormData", newVal);
      },
    },
    pwdUsername: {
      get: function () {
        return this.$store.getters["login/pwdUsername"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/setPwdUsername", newVal);
      },
    },
    pwdAuthOptions: {
      get: function () {
        return this.$store.getters["login/pwdAuthOptions"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/pwdAuthOptions", newVal);
      },
    },
    gotoUrl: {
      get: function () {
        return this.$store.getters["login/gotoUrl"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateGotoUrl", newVal);
        this.$store.dispatch("login/updateEncodedGotoUrl", newVal);
      },
    },
  },
  watch: {
    pwdUsername: function (newVal) {
      if (newVal) {
        this.formData[0].input[0].value = newVal;
      }
    },
    pwdAuthOptions: function (newVal) {
      if (newVal) {
        this.pwdAuthOptions = newVal;
      }
    },
    redirectToStepupPage: function (newVal) {
      if (newVal) {
        this.initializeStepup();
      }
    },
    currentStep: function (newVal) {
      if (newVal === "FLOW_INITIALIZED") {
        if (this.gotoUrl != null) {
          this.$router.push({
            name: "PwdLessOtpDelivery",
            query: { goto: this.gotoUrl },
          }); //.catch(err => {throw err});
        } else {
          this.$router.push({ name: "PwdLessOtpDelivery" });
        }
      }
    },
    redirectToSecProfilePage: function (newVal) {
      if (newVal) {
        window.location.href = newVal;
      }
    },
  },
};
</script>
<style scoped>
.pwdless_pass_forgot_text,
.back_icon_div {
  margin-bottom: 24px;
}

.pwdles_pass_sub_heading {
  margin-bottom: 4px;
}

.pwdless_pass_forgot_text {
  text-align: right;
}

.input_container {
  position: relative;
}

.toggle_text {
  position: absolute;
  right: 12px;
  top: 11.5px;
  border-bottom: 1px solid #000000;
  padding-bottom: 2px;
  cursor: pointer;
}

.pwdles_pass_main_heading {
  margin-bottom: 16px;
}

.pwdless_pass_user_gap {
  margin-bottom: 32px;
}
.isdisabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
