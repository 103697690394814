<template>
  <div>
    <h1 class="new_pwdless_main_heading pwdles_pass_main_heading">Log in</h1>
    <div class="new_pwdless_pass_sub_heading pwdles_pass_sub_heading">
      User ID
    </div>
    <div class="new_pwdless_pass_user pwdless_pass_user_gap">
      {{ this.pwdUsername }}
    </div>
    <form @submit.prevent="submitPasswordForm">
      <div class="new_pwdless_input_label">Password</div>
      <div class="input_container">
        <input
          class="new_pwdless_input_field"
          v-model="formData[1].input[0].value"
          v-on:change="onPwdEnter"
          :type="show1 ? 'text' : 'password'"
          autofocus
        />
        <a
          class="toggle_text new_pwdless_remember_me_text"
          v-if="formData[1].input[0].value"
          @click="show1 = !show1"
          v-on:keyup.space.enter="show1 = !show1"
          tabindex="0"
        >
          {{ !show1 ? "Show" : "Hide" }}
        </a>
      </div>
      <div class="new_pwdless_forgot_text pwdless_pass_forgot_text">
        <a
          class="new_pwdless_forgot_text_link"
          @click="forgotPopup('forgotUserPassword')"
          v-on:keyup.space.enter="forgotPopup('forgotUserPassword')"
          tabindex="0"
        >
          I forgot my password
        </a>
      </div>
      <div class="new_pwdless_remember_me">
        <div class="new_pwdless_checkbox_content">
          <input
            type="checkbox"
            class="new_pwdless_checkbox dark_bg_checkbox"
            v-model="defaultMethod"
          />
          <span class="new_pwdless_remember_me_text">
            Make this as my default log in method.
          </span>
        </div>
      </div>
      <div class="new_pwdless_btn_section">
        <button
          class="new_pwdless_submit_btn"
          :class="continueDisabled ? 'disabled' : ''"
          :disabled="continueDisabled"
          type="submit"
        >
          {{ $t("message.new_pwdless_login_btn") }}
        </button>
      </div>

      <!-- Popup code -->
      <div>
        <div id="noAccessToOtpModal" class="modal fade">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" :class="checkModal" :style="gethelp">
              <div class="close-icon-container">
                <a data-dismiss="modal" @click="closeCombinenow()">
                  <img
                    src="@/common/assets/styles/corp/images/close_black.png"
                    style="height: 11.7px; width: 11.7px"
                  />
                </a>
              </div>

              <pop-up-component></pop-up-component>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import commonMixin from "@/common/mixin/commonMixins";
import commonErrorMixin from "../common/common-error-mixin";
import PopUpComponent from "@/vbgauth/components/hybridExp/popups/PopUpComponent";
import {
  setPageDetails,
  eventTracking,
  errorSet,
} from "../../../../../public/adobeTagging";
export default {
  name: "NewPwdLessPassword",
  components: {
    PopUpComponent,
  },
  mixins: [commonMixin, commonErrorMixin],
  data() {
    return {
      defaultMethod: false,
      //pwdUsername: getCookie("pwdUserName"),
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      show1: false,
      whichRealm: true,
    };
  },
  mounted() {
    // if (this.getCurrentComponent.name !== "NewPwdLessPassword")
    //   this.setNavHistory({
    //     path: "/login/password",
    //     name: "NewPwdLessPassword",
    //   });
    this.$store.dispatch("login/updateUnifiedLoginAlerts", [], { root: true });
    setGotoUrlInClientLib(this.getParameterByName("goto"));
    this.gotoUrl = this.$route.query.goto;
    if (this.gotoUrl != null) {
      // determine if there is a goto with a hashtag
      let hashValue = window.location.hash.substr(1);
      if (hashValue != "") {
        let hashInUrl = "#" + window.location.hash.substr(1);
        if (this.gotoUrl != null) {
          this.gotoUrl += hashInUrl;
          this.$store.dispatch("login/updateGotoUrl", this.gotoUrl);
        }
      }
    }
    if (this.pwdUsername == null) {
      this.$router.push({ name: "PwdLessLoginRootComponent" });
    }
    if (this.optionMethod == "opt3") {
      this.defaultMethod = this.makeDefaultMethodTrue;
    }
    this.changeContextBiocatch("pwdless_login_password_page");
    if (this.urlOnMount != null || this.urlOnMount != undefined) {
      const hostUrl = this.urlOnMount;
      if (hostUrl.includes("/login/mobile")) {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      let iamvar_appCookie = getCookie("X-VZ-C_APP");
      if (iamvar_appCookie == "MVB") {
        this.whichRealm = false;
      }
    }
  },
  methods: {
    ...mapMutations("login", ["setNavHistory", "setRedirectToStepupPage"]),
    ...mapActions("cvsstepup", [
      "updateOTPAlwaysOn",
      "initializeStepup",
      "updateFlowType",
    ]),
    ...mapActions("login", [
      "UpdatePopupValue",
      "submitAMLoginForm",
      "smbFormSubmit",
      "submitPwdAMPasswordForm",
      "updateUrlOnMount", // updates urlOnMount on both cvsstepup and login modules
      "selectAccount",
      "updateVcgThrotteledOn",
      "updateProActive",
      "submitMobilePwdAMPasswordForm",
    ]),
    gotoPwdLessLogin() {
      if (iamvar_loginAssistant) this.updateProActive(true);
      this.$store.dispatch("login/updateEmptyUnifiedLoginAlerts", [], {
        root: true,
      });
      if (this.gotoUrl != null) {
        this.$router.push({
          name: "PwdLessLoginRootComponent",
          query: { goto: this.gotoUrl },
        }); //.catch(err => {throw err});
      } else {
        this.$router.push({ name: "PwdLessLoginRootComponent" });
      }
    },
    getParameter(url) {
      let decodeUrl = decodeURIComponent(document.location);
      url = url.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + url + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(decodeUrl);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    forgotPopup(value) {
      if (this.whichRealm) {
        $("#noAccessToOtpModal").modal({
          backdrop: "static",
        });
        this.UpdatePopupValue(value);
        var eventPayload = {};
        var eventName = null;
        if (value == "forgotUserId" || value == "forgotUserPassword") {
          eventName =
            value == "forgotUserId" ? "forgot_user_id" : "forgot_password";
          eventPayload = {
            accounts: {
              1: "My Business Wireless",
              2: "Verizon Enterprise Center",
              3: "My Business Wireline Fios, phone, internet & TV",
            },
          };
          eventTracking(eventName, eventPayload);
        } else if (value == "payWithoutLogin") {
          eventName = "pay_without_logging_in";
          eventPayload = {
            accounts: {
              1: "My Business Wireless",
              2: "My Business Wireline Fios, phone, internet & TV",
              3: "Verizon Enterprise Center",
            },
          };
          eventTracking(eventName, eventPayload);
        }
      } else {
        if (this.gotoUrl) {
          this.$router.push({
            name: "AMForgotPwdComponent",
            query: { goto: this.gotoUrl },
          });
        } else this.$router.push({ name: "AMForgotPwdComponent" });
      }
    },
    closeCombinenow() {
      if (this.popupValue == "combinenowPopupVal") {
        this.updateMergeStatus("closePopup");
      }
    },
    supportUrl() {
      if (this.gotoUrl != null) {
        this.$router.push({
          name: "PwdLessOption",
          query: { goto: this.gotoUrl },
        }); //.catch(err => {throw err});
      } else {
        this.$router.push({ name: "PwdLessOption" });
      }
    },
    onPwdEnter() {
      var passSubmit = document.getElementById("pwdClick");
      passSubmit.addEventListener("keyup", function (e) {
        if (e.code === "Enter") {
          e.preventDefault();
          this.submitPasswordForm;
        }
      });
    },
    submitPasswordForm: function (recaptchaToken) {
      this.clearAllErrors();
      this.formData[0].input[0].value = this.pwdUsername;
      let usernameOption = this.pwdUsername + "|" + "opt3";
      if (this.defaultMethod == true) {
        let payload = {
          formData: this.formData,
          rememberMethod: this.defaultMethod,
          usernameOption: usernameOption,
        };
        // update state with new captcha token
        if (this.captchaOn) this.updateCaptchaToken(recaptchaToken);

        this.submitPwdAMPasswordForm(payload);
      } else {
        let payload = {
          formData: this.formData,
        };
        // update state with new captcha token
        if (this.captchaOn) this.updateCaptchaToken(recaptchaToken);
        if (this.whichRealm) {
          this.submitPwdAMPasswordForm(payload);
        } else {
          this.submitMobilePwdAMPasswordForm(payload);
        }
      }
    },
    bannerContainer() {
      return this.getUnifiedLoginAlerts[0].type == "success" ||
        this.getUnifiedLoginAlerts[0].msg.includes("Too many failed attempts")
        ? "bannerMargin"
        : "";
    },
    getTypeOfAlert(index) {
      return index.type;
    },
    messageImage(index) {
      return index.type + "MessageImage";
    },
    getCloseIcon() {
      return ClearWhite;
    },
    deleteAlert(alert) {
      this.getEmptyUnifiedLoginAlerts.splice(
        this.getEmptyUnifiedLoginAlerts.indexOf(alert),
        1
      );
    },
    gethpop(value) {
      this.UpdatePopupValue(value);
      $("#noAccessToOtpModal").modal({
        backdrop: "static",
      });
    },
  },
  beforeDestroy() {
    this.formData[1].input[0].value = "";
  },
  computed: {
    ...mapGetters("cvsstepup", [
      "flowInitialized",
      "stepupReason",
      "urlOnMount",
      "otpAlwaysOn",
      "dnrStatus",
      "currentStep",
      "flowType",
    ]),
    ...mapGetters("cvsstepup", { getTransactionLoader: "submitLoader" }),
    ...mapGetters("login", [
      "getCurrentComponent",
      "authError",
      "submitLoader",
      "iamvar_enableCaptcha",
      "redirectToOptionsPage",
      "redirectToStepupPage",
      "redirectToSecProfilePage",
      "iamvar_idmUrl",
      "loginCaptchaKey",
      "getAlertType",
      "popupValue",
      "getInterstitialPage",
      "getUserSelectedAccount",
      "getUnifiedErrorCode",
      "rememberMeTrue",
      "makeDefaultMethodTrue",
      "combinenowValue",
      "remembermeUserName",
      "pwdUsername",
      "optionMethod",
      "getUnifiedLoginAlerts",
      "getEmptyUnifiedLoginAlerts",
      "loginAnotherWayEnable",
      "getVcgRedirectToCsqaStepup",
      "getVcgThrotteledOn",
      "gotoUrl"
    ]),
    formData: {
      get: function () {
        return this.$store.getters["login/formData"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateFormData", newVal);
      },
    },
    defaultMethod: {
      get: function () {
        return this.$store.getters["login/makeDefaultMethodTrue"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/makeDefaultMethodTrue", newVal);
      },
    },
    pwdUsername: {
      get: function () {
        return this.$store.getters["login/pwdUsername"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/setPwdUsername", newVal);
      },
    },
    pwdAuthOptions: {
      get: function () {
        return this.$store.getters["login/pwdAuthOptions"];
      },
    },
    gotoUrl: {
      get: function () {
        return this.$store.getters["login/gotoUrl"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateGotoUrl", newVal);
        this.$store.dispatch("login/updateEncodedGotoUrl", newVal);
      },
    },
    checkModal() {
      if (
        this.popupValue == "forgotUserId" ||
        this.popupValue == "forgotUserPassword"
      ) {
        return "modal-content-forgot";
      } else if (
        this.popupValue == "interstitial" &&
        this.getUnifiedErrorCode.includes("MYBIZ") &&
        this.getUnifiedErrorCode.includes("VEC")
      ) {
        return "modal-content-hybrid";
      } else if (
        this.popupValue == "interstitial" &&
        (this.getUnifiedErrorCode.includes("MYBIZ") ||
          this.getUnifiedErrorCode.includes("VEC"))
      ) {
        return "modal-content-int";
      } else if (this.popupValue == "payWithoutLogin") {
        return "modal-content-pwl";
      } else if (this.popupValue == "combinenowPopupVal") {
        return "modal-content-cn";
      } else if (this.popupValue == "gethelp") {
        return "modal-content-gethelp";
      } else {
        return "";
      }
      // return [{ 'modal-content-pwl': this.popupValue == 'payWithoutLogin' ? true : false }];
    },
    gethelp() {
      if (this.popupValue == "gethelp") {
        return "overflow: auto";
      }
    },
    continueDisabled() {
      return !this.formData[1].input[0].value;
    },
  },
  watch: {
    getUserSelectedAccount: function (selectedAccount) {
      $("#noAccessToOtpModal").modal("hide");
      if (selectedAccount && this.popupValue == "interstitial") {
        let payload = {
          formData: this.formData,
          rememberUser: this.rememberMe,
          additionalAuth: "VBG_LOGIN",
        };
        if (this.getUnifiedErrorCode.includes("INVALID")) {
          if (
            selectedAccount == "wireline" &&
            this.getUnifiedErrorCode.includes("INVALID")
          ) {
            // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
            this.submitAMLoginForm(payload);
          } else if (
            selectedAccount == "wireless" &&
            this.getUnifiedErrorCode.includes("INVALID")
          ) {
            // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
            this.submitAMLoginForm(payload);
          } else if (
            selectedAccount == "fios" &&
            this.getUnifiedErrorCode.includes("INVALID")
          ) {
            payload["additionalAuth"] = "INVALID_SMB_LOGIN";
            // to do - "authenticate" call with additional header parameter as "INVALID_SMB_LOGIN" respectively.
            this.submitAMLoginForm(payload);
          }
        } else {
          if (
            selectedAccount == "fios" &&
            this.getUnifiedErrorCode.includes("VALID")
          ) {
            if (this.getVcgThrotteledOn) {
              payload["additionalAuth"] = "VALID_SMB_LOGIN";
              this.submitAMLoginForm(payload);
              this.updateVcgThrotteledOn(false);
            } else {
              this.smbFormSubmit({
                username: this.formData[0].input[0].value,
                password: this.formData[1].input[0].value,
                rememberUser: this.rememberMe,
              });
            }
          } else if (
            selectedAccount == "wireline" &&
            this.getUnifiedErrorCode.includes("VALID")
          ) {
            // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
            this.submitAMLoginForm(payload);
          } else if (
            selectedAccount == "wireless" &&
            this.getUnifiedErrorCode.includes("VALID")
          ) {
            // to do - "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
            this.submitAMLoginForm(payload);
          }
        }
      } else if (
        (selectedAccount == "wireless" || selectedAccount == "wireline") &&
        this.popupValue == "forgotUserId"
      ) {
        this.$router.push({ path: "/login/recovery/forgotusername" });
        this.updateUserIdFlow("BASIC");
      } else if (
        (selectedAccount == "wireless" || selectedAccount == "wireline") &&
        this.popupValue == "forgotUserPassword"
      ) {
        this.$router.push({ path: "/login/recovery/forgotpassword" });
      } else if (
        selectedAccount == "fios" &&
        this.popupValue == "forgotUserPassword"
      ) {
        let fiosURLFP = "";
        let gotoURLFP = this.getParameterByName("goto");
        if (gotoURLFP) {
          // Added goto url to fios url
          fiosURLFP = `${iamvar_fios_fp_url}?goto=${iamvar_vbm_formsso}?target=${encodeURIComponent(
            gotoURLFP
          )}&clientId=vbm`;
        } else {
          fiosURLFP = iamvar_fios_fp_url + "?clientId=vbm";
        }
        window.location.href = fiosURLFP;
      } else if (
        selectedAccount == "fios" &&
        this.popupValue == "forgotUserId"
      ) {
        let fiosURLFU = "";
        let gotoURLFU = this.getParameterByName("goto");
        if (gotoURLFU) {
          // Added goto url to fios url
          fiosURLFU = `${iamvar_fios_fu_url}&goto=${iamvar_vbm_formsso}?target=${encodeURIComponent(
            gotoURLFU
          )}`;
        } else {
          fiosURLFU = iamvar_fios_fu_url;
        }
        window.location.href = fiosURLFU;
      } else if (
        selectedAccount == "wireless" &&
        this.popupValue == "payWithoutLogin"
      ) {
        window.location.href =
          iamvar_mbt_base_url +
          "/mbt/manageservice/payments.go/#/billpay/onetimepayment";
      } else if (
        selectedAccount == "fios" &&
        this.popupValue == "payWithoutLogin"
      ) {
        window.location.href = this.wireline_login;
      } else if (
        selectedAccount == "wireline" &&
        this.popupValue == "payWithoutLogin"
      ) {
        window.location.href = iamvar_vec_quickpay;
      }

      // to reset initial value
      this.selectAccount("null");
    },
    popupValue: function (newVal) {
      if (newVal == "combinenowPopupVal") {
        this.forgotPopup(newVal);
      }
    },
    pwdUsername: function (newVal) {
      if (newVal) {
        this.formData[0].input[0].value = newVal;
      }
    },
    redirectToStepupPage: function (newVal) {
      if (newVal) {
        this.initializeStepup();
        console.log("called initializeStepup from newpwdlesspassword");
        this.setRedirectToStepupPage(false);
      }
    },
    getInterstitialPage: function (newVal) {
      if (newVal) {
        this.forgotPopup("interstitial");
        this.$store.commit("login/setInterstitialPage", false);
      }
    },
    makeDefaultMethodTrue: function (newVal) {
      //if(newVal == true) {
      if (newVal) {
        this.defaultMethod = newVal;
      } //else {
      //this.defaultMethod = false
      //}
    },
    currentStep: function (newVal) {
      if (newVal === "FLOW_INITIALIZED" && this.flowType == "auth_sqa_flow") {
        if (this.gotoUrl != null) {
          this.$router.push({
            path: "/login/csqa",
            query: { goto: this.gotoUrl },
          }); //.catch(err => {throw err});
        } else {
          this.$router.push({ path: "/login/csqa" });
        }
      } else if (newVal === "FLOW_INITIALIZED") {
        if (this.gotoUrl != null) {
          this.$router.push({
            name: "PwdLessOtpDelivery",
            query: { goto: this.gotoUrl },
          }); //.catch(err => {throw err});
        } else {
          this.$router.push({ path: "/login/otpdelivery" });
        }
      }
    },
    getUnifiedLoginAlerts: function () {
      if (this.getUnifiedLoginAlerts.length > 0) {
        window.scrollTo(0, 0);
        this.formData[1].input[0].value = "";
      }
    },
    show1: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.show1 = false;
        }, 10000);
      }
    },
    redirectToSecProfilePage: function (newVal) {
      if (newVal) {
        window.location.href = newVal;
      }
    },
    getVcgRedirectToCsqaStepup: function (newVal) {
      if (newVal) {
        //vcg-vbg migration initialize csqa get-transaction call
        this.updateFlowType("auth_sqa_flow");
        this.initializeStepup();
      }
    },
  },
};
</script>

<style scoped>
.pwdles_pass_main_heading,
.pwdless_pass_user_gap,
.pwdless_pass_forgot_text,
.back_icon_div {
  margin-bottom: 24px;
}
.pwdles_pass_sub_heading {
  margin-bottom: 8px;
}
.pwdless_pass_forgot_text {
  text-align: right;
}
.input_container {
  position: relative;
}
.toggle_text {
  position: absolute;
  right: 12px;
  top: 11.5px;
  border-bottom: 1px solid #000000;
  padding-bottom: 2px;
  cursor: pointer;
}
</style>
