<template>
  <div>
    <h1
      class="new_pwdless_main_heading pwdles_pass_main_heading"
      v-if="!showMtn"
    >
      Select a verification method
    </h1>
    <div v-if="!showMtn" class="otp_delivery_sub_text new_pwdless_sub_heading">
      Message and data rates may apply.
    </div>
    <li>
      <div
        class="pwdless_options_section"
        v-for="(device, index) in devices"
        :key="device.deviceID"
        :id="device.deviceID"
        :tabindex="index + 3"
        @click="sendOTP(device, index)"
      >
        <div class="pwdless_secure_option pwdless_secure_option_gap">
          <div class="pwdless_option">
            <div class="pwdless_option_icon">
              <a>
                <img :src="getIconURL(device)" :alt="device.deviceType" />
              </a>
            </div>
            <div class="delivery_option_with_msg">
              <a>
                {{
                  device.deviceType == "BASIC" ||
                  device.deviceType == "SMARTPHONE"
                    ? "Text"
                    : device.deviceType == "EMAIL"
                    ? "Email"
                    : device.deviceType == "IVR"
                    ? "Call"
                    : device.deviceType
                }}
                <span class="delivery_method_recommented_delivery">
                  <span
                    class="secure_text"
                    v-if="
                      device.vzCarrier &&
                      (device.deviceType == 'SMARTPHONE' ||
                        device.deviceType == 'BASIC')
                    "
                    >Recommended</span
                  >
                  <!-- <div>
                                <span class="warning_icon_gap"
                                  ><img
                                    src="@/common/assets/images/warning.png"
                                    alt="warning"
                                /></span>
                                <span class="failed_text">
                                  Failed previously</span
                                >
                              </div> -->
                </span>

                <div class="pwdless_delivery_link">
                  <!-- ***-***-9879 -->
                  {{ device.deviceValue }}
                </div>
                <div
                  class="pwdless_delivery_addional_text"
                  v-if="
                    !(device.deviceType == 'SMARTPHONE' ||
                        device.deviceType == 'BASIC')
                  "
                >
                  Additional verification needed
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </li>
    <div
      class="new_pwdless_remember_me"
      v-if="this.pwdPassWord == ''"
      style="margin-top: 24px"
    >
      <p class="checkbox_line check_para check_bg">
        <input
          class="tc checkbox_input check_box_border"
          type="checkbox"
          id="ch"
          v-model="defaultMethod"
        />
        <label for="ch" class="label_text"
          >Make this my default log in method.</label
        >
      </p>
    </div>
    <div
      class="new_pwdless_remember_me"
      v-if="
        this.rememberThisDeviceEnable == 'basic' &&
        flowType !== 'login_assistant' &&
        !flowType.includes('forgot') &&
        !otpAlwaysOn
      "
    >
      <div class="new_pwdless_checkbox_content_otp">
        <input
          name="remember_me"
          class="new_pwdless_checkbox dark_bg_checkbox"
          type="checkbox"
          id="ch"
          v-model="rememberThisDevice"
        />
        <span for="ch" class="checkbox_primary_text">
          Remember this device for quick access.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import commonMixin from "@/common/mixin/commonMixins";
import commonErrorMixin from "../common/common-error-mixin";
import "@/common/assets/styles/tooltip.css";
import translations from "@/common/assets/json/messages1.json";
import {
  setPageDetails,
  eventTracking,
  errorSet,
} from "../../../../../public/adobeTagging";
import { mapWaitingActions } from "vue-wait";

export default {
  name: "NewOtpDelivery",
  components: {},
  mixins: [commonMixin, commonErrorMixin],
  props: ["showMtn"],
  data() {
    return {
      showDropdown: false,
      showResendOtpLink: false,
      checkVerifyPopUp: false,
      wirelessMobileNumber: null,
      userValidationInputClass: "",
      content3: translations.en.message["login_otp_tooltip"],
      idmTooltip: translations.en.message["idm_otp_help_icon_msg"],
      idmTooltipVerify: translations.en.message["idm_otp_help_icon_msg_verify"],
      content4: translations.en.message["wireless_no_help_icon_msg"],
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      emailIconSrc:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/email.svg",
      whichRealm: true,
      displayVerificationLink: false,
      //reSendVerification: true,
      aaEmailTimeout: 15,
      aaSmsTimeout: 10,
      defaultMethod: false,
      //pwdUsername: getCookie("pwdUserName"),
      //pwdPassWord: getCookie("pwdPassWord"),
      rememberThisDeviceEnable: "",
      rememberThisDevice: true,
    };
  },
  async mounted() {
    if (this.getChangedSelectedOption && !this.showMtn) {
      console.log("mounted-get-tran");
      this.setChangedSelectedOption(false);
      await this.initializeStepup();
    }
    if (this.showMtn) {
      let devices = this.devices.filter(
        (device) =>
          device.deviceType == "SMARTPHONE" || device.deviceType == "BASIC"
      );
      this.setOtpDevices(devices);
    }
    // this.setLoader(true);
    this.changeContextBiocatch("pwdless_login_otp_delivery_page");
    this.gotoUrl = this.$route.query.goto;
    if (this.gotoUrl != null) {
      // determine if there is a goto with a hashtag
      let hashValue = window.location.hash.substr(1);
      if (hashValue != "") {
        let hashInUrl = "#" + window.location.hash.substr(1);
        if (this.gotoUrl != null) {
          this.gotoUrl += hashInUrl;
          this.$store.dispatch("login/updateGotoUrl", this.gotoUrl);
        }
      }
    }
    setGotoUrlInClientLib(this.getParameterByName("goto"));
    this.getOtpDevices();
    // const isPageReloaded = window.performance && window.performance.getEntriesByType('navigation') && window.performance.getEntriesByType('navigation').map((nav) => nav.type).includes('reload')
    // if (isPageReloaded) {
    //   this.initializeStepup();
    // }
    if (this.pwdUsername == null && !this.flowType.includes("forgot")) {
      console.log("inside");
      this.$router.push({ name: "PwdLessLoginRootComponent" });
    }
    if (this.optionMethod == "opt2") {
      this.defaultMethod = this.makeDefaultMethodTrue;
    }
    /* Event Listener For Closing Select Box */
    document.addEventListener("click", (e) => {
      if (this.showDropdown && e.target.closest("#drpdn") === null) {
        this.showDropdown = false;
      }
    });
    this.rememberThisDeviceEnable = iamvar_pwdLessFlowType;
  },
  updated() {
    if (this.showDropdown && this.$refs.selectDeviceList.length > 1) {
      this.$refs.selectDeviceList[1].focus();
    }
  },
  beforeDestroy() {
    $(".modal-backdrop").remove();
  },
  methods: {
    ...mapMutations("login", ["setNavHistory"]),
    ...mapMutations("cvsstepup", [
      "setLoader",
      "setChangedSelectedOption",
      "setOtpDevices",
      "setSelectedDeviceIndex",
    ]),
    getIconURL(device) {
      return device.deviceType == "BASIC" || device.deviceType == "SMARTPHONE"
        ? require("@/common/assets/images/smsText.png")
        : device.deviceType == "EMAIL"
        ? require("@/common/assets/images/email.png")
        : require("@/common/assets/images/phone.png");
    },
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (
        match != null &&
        match.length > 2 &&
        typeof match[2] === "string" &&
        match[2].length > 0
      ) {
        return match[2];
      } else {
        return null;
      }
    },
    ...mapWaitingActions("cvsstepup", {
      getOtpDevices: "request_to_complete",
    }),
    //need to verify once again
    deviceOptionSelected(selDevice, selectionType) {
      if (selDevice.label == "Enter Wireless Number") {
        // this.isShowWirelessNumber = true;
        this.$store.dispatch("cvsstepup/showWirelessNumber", true);
      } else {
        // this.isShowWirelessNumber = false;
        this.$store.dispatch("cvsstepup/showWirelessNumber", false);
      }
      this.updateSelectedDevice(selDevice);
      if (selectionType === "enter") {
        // move focus to button if user
        // click enter after selecting an option from dropdown
        // this.$nextTick(() => this.$refs.submitButtonRef.focus());
      } else {
        // dont move focus to button if user select from mouse
        // this.$refs.dropdownRef.focus();
      }
    },
    async sendOTP(device, index) {
      this.clearAllErrors();
      await this.updateSelectedDevice(device);
      if (
        !this.vbgFlow &&
        (this.flowType == "forgot_password" ||
          this.flowType === "forgot_username" ||
          this.flowType == "auth")
      ) {
        if (
          this.selectedOtpDevice.deviceType == "EMAIL" ||
          this.selectedOtpDevice.deviceType == "SMARTPHONE"
        ) {
          this.checkVerifyPopUp = true;
          this.updatecantrcvLink(false);
          this.isModalOpen = true;
          this.displayVerificationLink = false;
        }
        this.$store.dispatch("forgotflow/updateVbgFlow", false);
      }
      if (this.flowType === "forgot_password" && this.ffSQAEnabled) {
        if (this.gotoUrl) {
          this.$router.push({
            path: "/login/stepup/sqa",
            query: { goto: gotoUrl },
          });
        } else this.$router.push({ path: "/login/stepup/sqa" });
      } else {
        eventTracking("One Time Passcode Request Initiated", "");
        // if valid
        /*if(this.wirelessMobileNumber) {
              this.userValidationErrorMethod();
            }*/
        let self = this;
        this.$store.dispatch(
          "cvsstepup/updateSetUserDeviceRegistration",
          this.rememberThisDevice
        );
        if (this.defaultMethod) {
          console.log("defaultMethod");
          let usernameOption = this.pwdUsername + "|" + "opt2";
          this.encryptDefaultMethod(usernameOption);
        } else {
          if (this.optionMethod == "opt2") {
            for (var i = 0; i < iamvar_vbgcRemCookieDomain.length; i++) {
              document.cookie =
                iamvar_makeDefaultMethodName +
                "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" +
                iamvar_vbgcRemCookieDomain[i] +
                ";path=" +
                iamvar_vbgcRemCookiePath +
                ";secure=" +
                iamvar_vbgcRemCookieSecure +
                ";comment=deleting from ui";
            }
          }
        }
        if(!this.showMtnDeliveryMethod){
          this.sendOtpToDevice(
            device.deviceId ? device.deviceId : this.selectedOtpDevice.deviceID
          );
        } else {
          this.setSelectedDeviceIndex(index)
        }
        self.showResendOtpLink = true;
      }
    },
    ...mapActions("cvsstepup", [
      "sendOtpToDevice",
      "updateSelectedDevice",
      "resendOtpToDevice",
      "updateVerificationSent",
      "updatecantrcvLink",
      "updateOTPAlert",
      "initializeStepup",
    ]),
    ...mapActions("login", ["encryptDefaultMethod"]),
    ...mapActions("forgotflow", [
      "updateUserVerified",
      "setFFTransactionId",
      "setFFUsername",
      "getUsername",
      "updateVbgFlow",
      "setIsSmbUser",
    ]),
    async initializeTokenizedFlow() {
      if (iamvar_polarisFlowToggle && this.sdarUserType) {
        // call polling api and show tokenized link screen
        if (this.devices.length > 0) {
          let device;
          const smartphone = this.devices.find(
            (device) => device.deviceType === "SMARTPHONE"
          );
          const basicDevice = this.devices.find(
            (device) => device.deviceType === "BASIC"
          );

          if (smartphone) {
            device = smartphone;
          } else if (basicDevice) {
            device = basicDevice;
          } else {
            device = this.devices.find((device) => device.deviceType === "IVR");
          }

          if (device) {
            console.log(device);
            this.selectedOtpDevice = device;
            this.$store.commit("cvsstepup/setHideOtpValidateComponent", false);
            this.sendOtpToDevice(device.deviceID);
          } else {
            console.log("======== error device not found ========");
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters("login", [
      "gotoUrl",
      "pwdUsername",
      "makeDefaultMethodTrue",
      "optionMethod",
      "pwdPassWord",
      "getCurrentComponent",
    ]),
    ...mapGetters("profile", ["sdarUserType"]),
    ...mapGetters("cvsstepup", [
      "flowType",
      "ffSQAEnabled",
      "getChangedSelectedOption",
      "otpAlwaysOn",
      "showMtnDeliveryMethod",
    ]),
    formData: {
      get: function () {
        return this.$store.getters["login/formData"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateFormData", newVal);
      },
    },
    vzigInstanceCreated: {
      get: function () {
        return this.$store.getters["cvsstepup/vzigInstance"];
      },
    },
    pwdAuthOptions: {
      get: function () {
        return this.$store.getters["login/pwdAuthOptions"];
      },
    },
    otpAlerts: {
      get: function () {
        return this.$store.getters["cvsstepup/otpAlerts"];
      },
      set: function (newVal) {
        this.$store.commit("cvsstepup/setOTPAlert", newVal);
      },
    },
    defaultMethod: {
      get: function () {
        return this.$store.getters["login/makeDefaultMethodTrue"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/makeDefaultMethodTrue", newVal);
      },
    },
    gotoUrl: {
      get: function () {
        return this.$store.getters["login/gotoUrl"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateGotoUrl", newVal);
        this.$store.dispatch("login/updateEncodedGotoUrl", newVal);
      },
    },

    ...mapGetters("cvsstepup", [
      "devices",
      "stepupReason",
      "otpPageMessages",
      "transactionId",
      "username",
      "flowType",
      "email",
      "submitLoader",
      "sqaEnabled",
      "pageTimeout",
      "isMobilenumberValid",
      "isShowWirelessNumber",
      "wirelessMob",
      "vbgFlow",
      "currentStep",
      "forgotPwdSqaTransactionId",
      "isSmbUser",
      "urlOnMount",
      "verificationSent",
      "cantrcvLink",
      "userDeniedOTP",
      "defaultSelectedDevice",
      "aaThrottled",
      "hideOtpValidateComponent",
    ]),
    otpSent: {
      get: function () {
        return this.$store.getters["cvsstepup/otpSent"];
      },
      set: function (newVal) {
        this.$store.dispatch("cvsstepup/updateOtpSent", newVal);
      },
    },
    resendPasscodeToSelectedDevice: {
      get: function () {
        return this.$store.getters["cvsstepup/resendPasscodeToSelectedDevice"];
      },
      set: function (newVal) {
        this.$store.dispatch(
          "cvsstepup/updateResendPasscodeToSelectedDevice",
          newVal
        );
      },
    },
    resendVerificationToSelectedDevice: {
      get: function () {
        return this.$store.getters[
          "cvsstepup/resendVerificationToSelectedDevice"
        ];
      },
      set: function (newVal) {
        this.$store.dispatch(
          "cvsstepup/updateResendVerificationToSelectedDevice",
          newVal
        );
      },
    },
    selectedOtpDevice: {
      get: function () {
        return this.$store.getters["cvsstepup/selectedOtpDevice"];
      },
      set: function (newVal) {
        this.$store.dispatch("cvsstepup/updateSelectedDevice", newVal);
      },
    },
    pageTimeout: {
      get: function () {
        return this.$store.getters["cvsstepup/pageTimeout"];
      },
      set: function (newVal) {
        this.$store.dispatch("cvsstepup/displayPageTimeoutWarning", newVal);
      },
    },
    mobDisable() {
      return this.otpSent ||
        this.selectedOtpDevice.deviceID == null ||
        this.selectedOtpDevice.deviceID == "select-label"
        ? true
        : false;
    },
    disabledAttr() {
      return this.otpSent ||
        this.selectedOtpDevice.deviceID == null ||
        this.selectedOtpDevice.deviceID == "select-label"
        ? true
        : false;
    },
  },
  watch: {
    otpSent: function (newVal) {
      if (newVal) {
      }
    },
    pageTimeout: function (newVal) {
      if (newVal) {
        $("#pageTimeoutModal").modal({
          backdrop: "static",
        });
      }
    },
    vzigInstanceCreated: function (newVal) {
      if (newVal != null) {
        this.getOtpDevices();
      }
    },
    makeDefaultMethodTrue: function (newVal) {
      if (newVal) {
        this.defaultMethod = newVal;
      } /* else {
          this.defaultMethod = false
        }*/
    },
    currentStep: function (newVal) {
      if (!this.vbgFlow && newVal === "OTP_VALIDATED") {
        $("#noAccessToOtpModal").modal("hide");
        this.updateUserVerified(true);
        this.setFFTransactionId(this.transactionId);
        this.setIsSmbUser(this.isSmbUser);
        if (this.flowType === "forgot_password") {
          this.setFFUsername(this.username);
          this.$router.push({ name: "AMForgotPwdComponent" });
        } else if (this.flowType === "forgot_username") {
          this.getUsername(this.email);
          // redirect user back to login page
          this.redirectBackToLogin(this.urlOnMount, this.gotoUrl);
        }
      } else if (
        !this.vbgFlow &&
        newVal === "OTP_INVALIDATED" &&
        (this.flowType === "forgot_password" ||
          this.flowType === "forgot_username" ||
          this.flowType == "auth")
      ) {
        $("#noAccessToOtpModal").modal("hide");
        this.displayVerificationLink = true;
      } else if (
        !this.vbgFlow &&
        (newVal === "IFG_TOKEN_EXPIRED" ||
          newVal === "IFG_TOKEN_PROCESS_ERROR" ||
          newVal === "IFG_TOKEN_INVALID_REQUEST_ERROR") &&
        (this.flowType === "forgot_password" ||
          this.flowType === "forgot_username" ||
          this.flowType == "auth")
      ) {
        $("#noAccessToOtpModal").modal("hide");
        //this.displayVerificationLink = true
        this.displayVerificationLink = false;
      } else if (newVal === "OTP_OPTIONS_DISPLAYED") {
        // send the tokenizedlink/otp if sdar user
        this.initializeTokenizedFlow();
      }
    },

    resendVerificationToSelectedDevice: function (newVal) {
      if (newVal) {
        if (
          !this.vbgFlow &&
          (this.flowType == "forgot_password" ||
            this.flowType === "forgot_username" ||
            this.flowType == "auth")
        ) {
          this.checkVerifyPopUp = true;
          this.updatecantrcvLink(false);
          $("#noAccessToOtpModal").modal({
            backdrop: "static",
          });
          // this.$store.dispatch('forgotflow/forgotPwdSqaTransactionId',this.forgotPwdSqaTransactionId)
          this.$store.dispatch("forgotflow/updateVbgFlow", false);
          this.displayVerificationLink = false;
          this.resendVerificationToSelectedDevice = false;
        }
      }
    },
    hideOtpValidateComponent: function (newVal) {
      console.log("hide-component", newVal);
      if (newVal == false) {
        const gotoUrl = this.getParameterByName("goto");
        if (gotoUrl) {
          if (this.rememberThisDevice) {
            this.$router.push({
              name: "PwdLessOtpValidate",
              query: { goto: gotoUrl },
              params: { rememberThisDevice: this.rememberThisDevice },
            });
          } else {
            this.$router.push({
              name: "PwdLessOtpValidate",
              query: { goto: gotoUrl },
            });
          }
        } else {
          if (this.rememberThisDevice) {
            this.$router.push({
              name: "PwdLessOtpValidate",
              params: { rememberThisDevice: this.rememberThisDevice },
            });
          } else {
            this.$router.push({ name: "PwdLessOtpValidate" });
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.pwdless_pass_forgot_text,
.back_icon_div {
  margin-bottom: 24px;
}
.pwdles_pass_sub_heading {
  margin-bottom: 4px;
}
.pwdless_pass_forgot_text {
  text-align: right;
}
.input_container {
  position: relative;
}
.toggle_text {
  position: absolute;
  right: 12px;
  top: 11.5px;
  border-bottom: 1px solid #000000;
  padding-bottom: 2px;
  cursor: pointer;
}
.pwdles_pass_main_heading {
  margin-bottom: 12px;
}
.pwdless_pass_user_gap {
  margin-bottom: 32px;
}
.otp_delivery_sub_text {
  margin-bottom: 24px;
}
</style>
