<template>
  <div class="vbg-rsp-verify-method-container">
    <form @submit.prevent="OnOtpSubmit()">
      <h1 class="new_pwdless_main_heading pwdles_main_heading">
        Verify your delivery method
      </h1>
      <div class="new_pwdless_pass_sub_heading sub_heading_gap">
        Please enter the one-time passcode you received at
        {{ maskedValue }}. <br />Passcode is valid for 10 minutes.
      </div>
      <div class="verify-input-container">
        <label class="new_pwdless_input_label" for="onepasscode"
          >One-time passcode</label
        >
        <input
          type="password"
          id="onepasscode"
          name="onepasscode"
          v-model="onepasscode"
          minlength="6"
          maxlength="6"
          class="new_pwdless_input_field"
          tabindex="5"
          @keypress="onlyNumber"
        />
        <div v-if="onepasscode.length !== 0 && onepasscode.length !== 6">
          <p class="vbg-rsp-warning-msg">Please enter only 6-digits Passcode</p>
        </div>
      </div>
      <div class="vbg-sp-btn-continue">
        <button
          class="btn vbg-ff-btn-continue vbg-ff-btn-width140 btn-verify spacing05"
          type="submit"
          :class="{
            'vbg-ff-disabled': submitLoader || onepasscode.length !== 6,
          }"
          :disabled="submitLoader || onepasscode.length !== 6"
          tabindex="6"
          id="rsp-verify-btn"
          name="rsp-verify-btn"
          @keyup.enter="OnOtpSubmit()"
        >
          Verify
        </button>
        <button
          type="button"
          class="btn vbg-ff-btn-cancel vbg-ff-btn-width140 btn-verify spacing05"
          @click="cancelVerifyOtp()"
          tabindex="7"
          id="rsp-cancel-verify-btn"
          name="rsp-cancel-verify-btn"
        >
          Cancel
        </button>
      </div>
      <div class="new_pwdless_forgot_text new_pwdless_bottom_link">
        <a
          @click="onSubmit()"
          id="resendlink"
          class="new_pwdless_forgot_text_link"
          tabindex="0"
          >Resend passcode</a
        >
      </div>

      <div class="new_pwdless_forgot_text new_pwdless_bottom_link">
        <a
          @click="openModel()"
          id="launchInfo"
          class="new_pwdless_forgot_text_link"
          tabindex="0"
          >I can't receive a one-time passcode</a
        >
      </div>
      <!-- popup for cannot receive one time passcode -->
      <div id="noAccessToOtpModal" class="modal fade">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content modal-content-container">
            <div class="modal-container">
              <div class="close-icon-container" tabindex="1">
                <a data-dismiss="modal" @click="closeModal()">
                  <img
                    src="@/common/assets/styles/corp/images/close_black.png"
                    style="height: 20px; width: 20px"
                  />
                </a>
              </div>
              <div class="window-content mar-bottom50">
                <div>
                  <h2 class="martop0 marbottom20 modal-content-title">
                    Can't receive a one time passcode?
                  </h2>
                  <p class="modal-content-description">
                    Please try another delivery method, contact your company
                    administrator, or
                    <a
                      href="https://www.verizon.com/business/support/"
                      style="text-decoration: underline; cursor: pointer"
                      class="vbg-ff-focus"
                      target="_blank"
                      tabindex="2"
                      >visit our support page.</a
                    >
                  </p>
                </div>
              </div>

              <div class="modal-btn-container martop100">
                <button
                  type="button"
                  data-dismiss="modal"
                  @click="closeModal()"
                  class="btn primary_black marright20 martop50 oauth-close-button bttn-container vbg-rel-bottom-40 spacing05"
                  tabindex="3"
                  id="rsp-close-modal-btn"
                  name="rsp-close-modal-btn"
                >
                  <span>Close</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "NewVerifyOTPComponent",
  data() {
    return { onepasscode: "" };
  },
  watch: {
    showVerifyOTP: function (new_val) {
      if (!new_val) this.setDeliveryComponentName("NewAddOTPComponent");
    },
  },
  computed: {
    ...mapGetters("profile", [
      "uuID",
      "maskedValue",
      "showVerifyOTP",
      "getUserInputText",
      "getSelectedOtpType",
    ]),
    ...mapGetters("login", ["submitLoader"]),
  },
  methods: {
    ...mapActions("profile", [
      "resetShowVerifyOtp",
      "verifyOtpCode",
      "sendotpToDevice",
    ]),
    ...mapActions("login", ["updateUnlockMyAccountAlerts"]),
    ...mapMutations("login", ["setDeliveryComponentName", "setLoader","setCommonErrors"]),
    openModel() {
      $("#noAccessToOtpModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    closeModal() {
      $("#noAccessToOtpModal").modal("hide");
    },
    async onSubmit() {
      this.setLoader(true);
      try {
        const payload = {
          uuID: this.uuID,
        };
        if (this.getSelectedOtpType == "email") {
          payload.emailAddress = this.getUserInputText;
          payload.otpType = "email";
        } else if (this.getSelectedOtpType == "call") {
          payload.mtn = this.getUserInputText.replace(/-/g, "");
          payload.countryCode = "1";
          payload.otpType = "ivr";
        } else if (this.getSelectedOtpType == "text") {
          payload.mtn = this.getUserInputText.replace(/-/g, "");
          payload.countryCode = "1";
          payload.otpType = "sms";
        }

        // submit addotpdevice request
        await this.sendotpToDevice(payload);
        this.setLoader(false);
      } catch (error) {
        this.setLoader(false);
      }
    },
    async OnOtpSubmit() {
      this.setLoader(true);
      try {
        const payload = {
          uuID: this.uuID,
          otpToken: this.onepasscode,
          emailAddress: "",
          mtn: "",
          countryCode: "1",
        };
        if (this.getSelectedOtpType == "email") {
          payload.emailAddress = this.getUserInputText;
          payload.deliveryMethodSelected = "email";
        } else if (this.getSelectedOtpType == "text") {
          payload.mtn = this.getUserInputText.replace(/-/g, "");
          payload.deliveryMethodSelected = "sms";
        } else if (this.getSelectedOtpType == "call") {
          payload.mtn = this.getUserInputText.replace(/-/g, "");
          payload.deliveryMethodSelected = "ivr";
        }

        await this.verifyOtpCode(payload);
        this.setLoader(false);
      } catch (error) {
        this.setLoader(false);
      }
    },
    cancelVerifyOtp() {
      this.resetShowVerifyOtp(false);
      this.updateUnlockMyAccountAlerts([]);
      this.setCommonErrors();
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
.vbg-rsp-verify-method-container {
  margin-top: 0;
}
.new_pwdless_input_field {
  margin-bottom: 0;
}
.pwdles_main_heading {
  margin-bottom: 12px;
}
.sub_heading_gap {
  margin-bottom: 32px;
}
.vbg-sp-btn-continue {
  margin-top: 32px;
  margin-bottom: 16px;
}
.new_pwdless_bottom_link {
  margin-bottom: 16px;
}
.vbg-rsp-warning-msg {
  margin-top: 4px;
  height: auto;
}
</style>
