<template>
  <div>
    <h1 class="new_pwdless_main_heading pwdles_main_heading">
      Reset your password
    </h1>

    <div class="new_pwdless_sub_heading pwdles_sub_heading">
      Your new password cannot match your previous passwords or your User ID.
    </div>
    <form @submit.prevent="onSubmit()" ref="createPForm">
      <div class="new_pwdless_input_label">New password</div>
      <div class="input_container strength_label toaster_parent">
        <input
          class="new_pwdless_input_field new_pwdless_input_reset_field"
          id="pwd"
          name="pwd"
          v-model="userPwd"
          :type="show1 ? 'text' : 'password'"
          :class="userPwd 
                    ? (passwordInvalidClass() == 'orangeLoader' 
                      ? 'orange_input': 'green_input')
                    : ''"
          @input="checkPassword"
          @focus="showSuggestionBox()"
          @blur="hideSuggestionBox()"
          autocomplete="new-password"
        />
        <div class="new_pwdless_input_label" v-if="userPwd !== ''">
          <span v-if="passwordInvalidClass() == 'greenLoader'">Password strength : {{pwdResetStrength}}</span>
          <span v-else-if="passwordInvalidClass() == 'orangeLoader' && userPwd && checkSequenceValidationError && enhanceFpFlow">Avoid sequences like abcd, 1234, AAAA, 1111.</span>
          <span v-else-if="passwordInvalidClass() == 'orangeLoader' && passwordBlkListValidation && enhanceFpFlow">Password is too easy to guess. Try another.</span>
          <span v-else-if="passwordInvalidClass() == 'orangeLoader' && userPwd && enhanceFpFlow && !this.displaySuggestionBox">Password doesn't meet all requirement</span>
        </div>
        <div v-if="displaySuggestionBox" class="toaster_child">
          <div class="tooltip_heading">
            Password strength :
            {{ pwdResetStrength }}
          </div>
          <!-- {{ this.validPd }} -->
          <div class="validations_container">
              <div class="pwd-progress-bar">
                <div class="pwd-progress-bar-black" v-bind:style="{ width: computedPwdProgress }"></div>
              </div>
            <div
              v-for="(req, i) in this.pwdReq"
              :key="i"
              :class="i ==3 ? 'list_gap':''"
              style="
                display: flex;
                height: 20px;
                margin-left: 5px;
                /* margin-bottom: 16px; */
              "
            >
              <div
                v-if="req.required"
                :class="[
                  pwdReqMatch[i].matched == 'matched'
                    ? 'vbg-tick-mark'
                    : 'unmatched-dot',
                  req.type == 'cannot_contain_special_characters' ? '' : '',
                ]"
              >
                <img
                  v-if="pwdReqMatch[i].matched == 'matched'"
                  :src="checkIcon"
                />
              </div>
              <div
                v-if="req.required"
                class="pwd-progress-bar-text"
                :class="[
                  pwdReqMatch[i].matched == 'matched' ? 'greycontentcolor' : '',
                ]"
              >
              <div>
                {{ req.display_msg }}
              </div>
              </div>
            </div>
          </div>
        </div>

        <span
          v-if="userPwd"
          class="toggle_text new_pwdless_remember_me_text"
          @click="show1 = !show1"
          >{{ show1 ? "Hide" : "Show" }}</span
        >
        <!-- <div v-if="pd_input !== ''">
                    <span class="new_pwdless_input_label"
                      >Password strength: strength</span
                    >
                  </div> -->
      </div>
      <div class="new_pwdless_input_label" :class="{'vbg-rsp-disabled-label':passwordInvalidClass() == 'orangeLoader'}">Re-enter password</div>
      <div class="input_container">
        <input
          class="new_pwdless_input_field new_pwdless_input_reset_field"
          id="pwdConfirm"
          name="pwdConfirm"
          v-model="userReenteredPwd"
          :type="show2 ? 'text' : 'password'"
          :class="passwordInvalidClass() == 'orangeLoader' ? 'vbg-rsp-disabled-label' : userReenteredPwd 
                    ? (repasswordValidClass() == 'orangeLoader' 
                      ? 'orange_input': 'green_input'): 
                    ''" 
          autocomplete="new-password"
          @click.right.prevent
          @copy.prevent
          @paste.prevent
          :disabled="!this.validPd"
          @blur="showTooltip = false"
        />
        <div
          class="new_pwdless_input_label"
          v-if="userReenteredPwd && repasswordValidClass() == 'greenLoader'"
        >
          Your passwords match!
        </div>
        <div
          class="new_pwdless_input_label"
          v-if="userReenteredPwd && repasswordValidClass() == 'orangeLoader'"
        >
          <span>Password do not match.</span>
        </div>

        <span
          v-if="userReenteredPwd"
          class="toggle_text new_pwdless_remember_me_text"
          @click="show2 = !show2"
          >{{ !show2 ? "Show" : "Hide" }}</span
        >

        <!-- <div v-if="pd_input === confirm_pd_input && pd_input !== ''">
                    <span class="new_pwdless_input_label"
                      >Your passwords match!</span
                    >
                  </div> -->
      </div>
      <div class="pwd_less_btn_reset_container">
        <div class="new_pwdless_btn_section">
          <button
            class="new_pwdless_submit_btn new_pwdless_reset_btn"
            type="submit"
            :class="isDisabledClass"
            :disabled="isDisabledButton"
            ref="submitButton"
            id="reset-pwd-btn"
            name="reset-pwd-btn"
          >
            Reset password
          </button>
        </div>
        <div class="new_pwdless_btn_section new_pwdless_btn_section_mobile">
          <button
            class="new_pwdless_submit_btn pwd_cancel_btn new_pwdless_reset_btn"
            @click="cancelPwdRecovery()"
            type="button"
            id="reset-pwd-cancel-btn"
            name="reset-pwd-cancel-btn"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
  <script>
import translations from "@/common/assets/json/messages1.json";
import { mapGetters, mapActions, mapMutations } from "vuex";
import warningIcon from "@/common/assets/styles/corp/images/LightBlack.png";
import checkIcon from "@/common/assets/styles/corp/images/checkmark.png";
import commonMixin from "@/common/mixin/commonMixins";
import commonErrorMixin from "../common/common-error-mixin";
export default {
  name: "NewAMForgotPwdComponent",
  mixins: [commonMixin, commonErrorMixin],
  mounted() {
    // this.setNavHistory({path:'',name:"NewAMForgotPwdComponent"});
    window.scrollTo(0, 0);
    // this.getPassswordBlackList();
    this.getPassswordBlackList("reset_pass");
    this.readConfig("vbg_config");
    this.changeContextBiocatch("forgot_password_reset_password_page");
  },
  data() {
    return {
      showTooltip: false,
      validPd: false,
      whichRealm: true,
      uname: "",
      userPwd: "",
      userReenteredPwd: "",
      pwdResetSuggestion: null,
      pwdResetStrength: "", // Strong/Medium/Weak
      pwdValid: false,
      fieldRules: [(v) => !!v || "Please fill out this field"],
      valid: false,
      noOfPwdRequirements: 0,
      pwdProgressWidth: "10%",
      passwordBlkList: false,
      invalidCharacters: false,
      warningIcon: warningIcon,
      checkIcon: checkIcon,
      show1: false,
      show2: false,
      displaySuggestionBox: false,
      passwordBlkListValidation: false,
      checkSequenceValidationError: false,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      invalidCharRegex: /[@()_~`!#$%\^&*+=\-\[\]\\';,/{}|\\": <>\?]/,
    };
  },
  methods: {
    ...mapActions("forgotflow", [
      "processInputs",
      "resetUserPassword",
      //added password blocklist functions
      "getPassswordBlackList",
      "containsBlackListPassword",
      "readConfig",
    ]),
    ...mapActions("cvsstepup", ["updateFpAlerts"]),
    ...mapActions("profile", ["loggSequences"]),
    ...mapMutations("login", ["setNavHistory"]),
    passwordInvalidClass() {
      if (
        !this.pwdValid ||
        this.invalidCharacters ||
        this.passwordBlkListValidation ||
        this.checkSequenceValidationError
      )
        return "orangeLoader";
      return "greenLoader";
    },
    repasswordValidClass() {
      if (this.isRePwdValid()) return "greenLoader";
      return "orangeLoader";
    },
    cancelPwdRecovery() {
      this.$store.dispatch("cvsstepup/resetState");
      this.$store.dispatch("forgotflow/resetState");
      this.updateFpAlerts([]);
      this.$router.push({ name: "AMForgotPwdComponent" }).catch((err) => {
        throw err;
      });
    },
    isRePwdValid() {
      if (this.userPwd === this.userReenteredPwd) {
        return true;
      } else return false;
    },
    passwordBlkListValue: function (params) {
      this.passwordBlkList = params;
    },
    onSubmit: async function () {
      this.clearAllErrors();
      this.showTooltip = false;
      this.$refs.submitButton.blur();
      this.updateFpAlerts([]);
      if (this.isRePwdValid()) {
        await this.resetUserPassword(this.userReenteredPwd);
      }
    },

    checkPassword: function () {
      this.passwordBlkListValidation = false;
      if (this.enhanceFpFlow) {
        for (let i = 0; i < this.pwdReq.length; i++) {
          if (this.pwdReq[i].required) {
            if (this.pwdReq[i].type === "length") {
              if (
                this.userPwd.length >= this.pwdReq[i].range.low &&
                this.userPwd.length <= this.pwdReq[i].range.high
              ) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if (this.pwdReq[i].type === "lowercase_letter") {
              if (/[a-z]/.test(this.userPwd)) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if (this.pwdReq[i].type === "number") {
              if (/\d/.test(this.userPwd)) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if (
              this.pwdReq[i].type === "cannot_contain_special_characters"
            ) {
              let invalidCharsRegex = new RegExp(/[<>]/);
              if (!invalidCharsRegex.test(this.userPwd) && this.userPwd) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if (this.pwdReq[i].type === "special_characters") {
              let validCharsRegex = new RegExp(
                /[-\\~`{:;"'?.|,}/[!@#$%^&*()_+=\]]/
              );
              if (validCharsRegex.test(this.userPwd) && this.userPwd) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            }
          }
        }
        this.containsBlackListPassword(this.userPwd);
        if (this.userPwd != "" && this.userPwd.length > 0) {
          if (!this.success) {
            this.passwordBlkListValidation = false;
          } else {
            this.passwordBlkListValidation = true;
          }
        }
        this.checkSequence();
      } else {
        for (let i = 0; i < this.pwdReq.length; i++) {
          if (this.pwdReq[i].required) {
            if (this.pwdReq[i].type === "length") {
              if (this.userPwd.length >= this.pwdReq[i].range.low) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            }
            if (this.pwdReq[i].type === "uppercase_letter") {
              if (/[A-Z]/.test(this.userPwd)) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if (this.pwdReq[i].type === "lowercase_letter") {
              if (/[a-z]/.test(this.userPwd)) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if (this.pwdReq[i].type === "number") {
              if (/\d/.test(this.userPwd)) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            }
            // blacklist password
            else if (this.pwdReq[i].type === "complexity") {
              this.containsBlackListPassword(this.userPwd);
              if (
                !this.success &&
                this.userPwd != "" &&
                this.userPwd.length > 0
              ) {
                this.passwordBlkList = true;
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.passwordBlkList = false;
                this.pwdReqMatch[i].matched = "unmatched";
              }
            }
            ////
          }
        }
        // reset invalid characters
        this.invalidCharacters = false;

        // check for invalid characters (<,>,")
        let invalidCharsRegex = new RegExp(/["<>]/);
        if (invalidCharsRegex.test(this.userPwd) && !this.enhanceFpFlow) {
          this.invalidCharacters = true;
        }
      }
      const matchArray = this.pwdReqMatch.map((res) => res.matched);
      this.validPd = matchArray.every((res) => res == "matched");
      this.displaySuggestionBox = true;
    },
    changePwdProgressWidth: function (pwdStrength) {
      // if(pwdStrength === 'Weak') {
      //   this.pwdProgressWidth = '25px solid black'
      // } else if(pwdStrength === 'Medium') {
      //   this.pwdProgressWidth = '100px solid black'
      // } else if(pwdStrength === 'Strong') {
      //   this.pwdProgressWidth = '210px solid black'
      // }
      if (pwdStrength === "Weak") {
        this.pwdProgressWidth = "10%";
      } else if (pwdStrength === "Medium") {
        this.pwdProgressWidth = "70%";
      } else if (pwdStrength === "Strong") {
        this.pwdProgressWidth = "100%";
      }
    },
    hideSuggestionBox: function () {
      this.displaySuggestionBox = false;
    },
    showSuggestionBox: function () {
      this.displaySuggestionBox = true;
      //if(this.userPwd.length > 0) {
      //  this.displaySuggestionBox = true
      //} else {
      //  this.displaySuggestionBox = false
      //}
    },
    checkSequence: function () {
      let sequenceMatched = false;
      this.checkSequenceValidationError = false;
      let convertToLowerCase = this.userPwd.toLowerCase();
      let checkString = convertToLowerCase.split("");
      let dupString = this.userPwd.split("");
      let requestPayload = "";
      let sequenceVal = "";
      for (let i = 3; i < checkString.length; i++) {
        if (!this.invalidCharRegex.test(checkString[i])) {
          if (
            checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() ==
              1 &&
            checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() ==
              1 &&
            checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() ==
              1
          ) {
            sequenceMatched = true;
            sequenceVal =
              dupString[i - 3] +
              dupString[i - 2] +
              dupString[i - 1] +
              dupString[i];
            break;
          }
          if (
            checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() ==
              0 &&
            checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() ==
              0 &&
            checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() ==
              0
          ) {
            sequenceMatched = true;
            sequenceVal =
              dupString[i - 3] +
              dupString[i - 2] +
              dupString[i - 1] +
              dupString[i];
            break;
          }
          if (
            checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() ==
              -1 &&
            checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() ==
              -1 &&
            checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() ==
              -1
          ) {
            sequenceMatched = true;
            sequenceVal =
              dupString[i - 3] +
              dupString[i - 2] +
              dupString[i - 1] +
              dupString[i];
            break;
          }
        }
      }
      if (sequenceMatched) {
        this.checkSequenceValidationError = true;
        requestPayload = {
          userID: this.username,
          sequence: sequenceVal,
        };
        this.loggSequences(requestPayload);
      }
    },
  },
  computed: {
    ...mapGetters("forgotflow", [
      "pwdReq",
      "noOfPwdReq",
      //blocklist password
      "passwordBlackListC",
      "passwordBlackListE",
      ///////
      "iamvar_enableCaptcha",
      "iamvar_enableJCaptcha",
      "pwdHistoryReqMet",
      "success",
      "submitLoader",
      "enhanceFpFlow",
      "resetPwdSuccess",
    ]),
    ...mapGetters("cvsstepup", ["username"]),
    ...mapGetters('login', [
      'urlOnMount', 'gotoUrl'
     ]),
    pwdReqMatch: {
      get: function () {
        return this.$store.getters["forgotflow/pwdReqMatch"];
      },
      set: function (newVal) {
        this.$store.dispatch("forgotflow/updatePwdReqMatch", newVal);
      },
    },
    computedPwdProgress: function () {
      return this.userPwd.length > 0 ? this.pwdProgressWidth : "0%";
    },
    // createPasswordDisabled() {
    //   return !this.userPwd || !this.userReenteredPwd ||this.userPwd != this.userReenteredPwd || this.pwdResetStrength == 'Weak' || this.invalidCharacters
    // },
    isDisabledClass() {
      if (
        this.userPwd == "" ||
        this.userReenteredPwd == "" ||
        (this.userPwd && this.userPwd !== this.userReenteredPwd) ||
        this.pwdResetStrength == "Weak" ||
        this.invalidCharacters ||
        this.passwordBlkListValidation ||
        this.checkSequenceValidationError
      ) {
        return "disabled";
      } else {
        return "";
      }
    },
    isDisabledButton() {
      if (
        this.userPwd == "" ||
        this.userReenteredPwd == "" ||
        (this.userPwd && this.userPwd !== this.userReenteredPwd) ||
        this.pwdResetStrength == "Weak" ||
        this.invalidCharacters ||
        this.passwordBlkListValidation ||
        this.checkSequenceValidationError
      ) {
        return true;
      } else {
        return false;
      }
    },
    progressBarLevel() {
      return this.userPwd && this.userPwd.length < 8
        ? "strength_progressive_bar strength_progressive_bar25 strength_progressive_bar_weak"
        : this.userPwd && this.userPwd.length == 8
        ? "strength_progressive_bar strength_progressive_bar50  strength_progressive_bar_weak"
        : this.userPwd && this.userPwd.length == 9
        ? "strength_progressive_bar strength_progressive_bar75 strength_progressive_bar_weak"
        : "strength_progressive_bar strength_progressive_bar100 strength_progressive_bar_weak";
    },
  },
  watch: {
    pwdValid: function (newVal) {
      if (newVal) {
        if (
          this.userPwd.length >= 8 &&
          this.userPwd.length < 10 &&
          !this.passwordBlkListValidation
        ) {
          this.pwdResetStrength = "Medium";
          this.changePwdProgressWidth("Medium");
        } else if (
          this.userPwd.length >= 10 &&
          !this.passwordBlkListValidation
        ) {
          this.changePwdProgressWidth("Strong");
          this.pwdResetStrength = "Strong";
        }
      }
      // if not valid, default pwd bar to weak
      if (!newVal) {
        this.changePwdProgressWidth("Weak");
      }
    },
    userPwd: function (newVal) {
      //if(newVal.length <= 0) {
      //  this.displaySuggestionBox = false;
      //}
      if (this.pwdValid) {
        if (newVal.length < 8) {
          this.changePwdProgressWidth("Weak");
        } else if (
          newVal.length >= 8 &&
          newVal.length < 10 &&
          !this.passwordBlkListValidation
        ) {
          this.pwdStrengthClass = "progress-bar-warning";
          this.pwdResetStrength = "Medium";
          this.changePwdProgressWidth("Medium");
        } else if (newVal.length >= 10 && !this.passwordBlkListValidation) {
          this.pwdStrengthClass = "progress-bar-success";
          this.pwdResetStrength = "Strong";
          this.changePwdProgressWidth("Strong");
        }
      }
    },
    pwdHistoryReqMet: function (newVal) {
      if (!newVal) {
        console.log("Pwd history req not met");
        this.pwdResetSuggestion = "Password history requirements are not met.";
      }
    },
    pwdReqMatch: {
      deep: true,
      handler(newVal) {
        var noMatched = 0;
        for (var i = 0; i < newVal.length; i++) {
          if (newVal[i].required) {
            if (newVal[i].matched === "unmatched") {
              this.pwdValid = false;
              this.pwdResetStrength = "Weak";
              break;
            } else {
              noMatched += 1;
              if (noMatched === this.noOfPwdReq) {
                this.pwdValid = true;
              }
            }
          }
        }
      },
    },
    show1: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.show1 = false;
        }, 10000);
      }
    },
    show2: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.show2 = false;
        }, 10000);
      }
    },
    resetPwdSuccess: function (newVal) {
      if (newVal) {
        // redirecting to the login pages based on the urlOnMount
        this.redirectBackToLogin(this.urlOnMount, this.gotoUrl);
      }
    },
  },
};
</script>
  <style scoped>
.green_input,
.green_input:focus {
  border: 1px solid #008331 !important;
  background-color: #dcf5e6;
}
.orange_input,
.orange_input:focus {
  border: 1px solid #B95319 !important;
  background-color: #FFECE0;
}
.validation_para,
.valid_conditions {
  font-size: 16px;
  color: #000000;
  margin-bottom: 16px;
}

.invalid_conditions {
  font-size: 16px;
  margin-bottom: 16px;
  color: #cccccc;
}

.validation_criteria {
  margin-left: 20px;
}

.unmatched-dot {
  height: 6px;
  width: 6px;
  background-color: #000000;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 6px;
  margin-bottom: 10px;
}

.vbg-tick-mark {
  margin-right: 10px;
  width: 6px;
  height: 20px;
}

.vbg-tick-mark img {
  height: 12px;
  width: 12px;
}

.vbg-tick-mark.validationStyle {
  margin-top: 19px;
}
.toaster_parent {
  position: relative;
}
.toaster_child {
  position: absolute;
  top: -200px;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #000000;
  z-index: 1000;
  border-radius: 4px;
}
.toaster_child::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 14%;
  transform: translateX(-50%);
  width: 12px;
  height: 16px;
  background-image: url("@/common/assets/styles/corp/images/down_arrow.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.tooltip_heading {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  font-family: "VzNHGeTX-bold11";
  color: #000000;
}

.strength_bar_container_label {
  width: 30%;
  display: flex;
  justify-content: space-between;
  color: #000000;
}

.strength_bar_container {
  width: 30%;
  display: flex;
  justify-content: space-between;
  color: #000000;
}

.strength_bar_container > span {
  font-size: 16px;
  color: #000000;
}

.strength_progressive_bar {
  /* border: 1px solid #cccccc; */
  height: 2px;
  /* width: 100%; */
  margin-bottom: 12px;
  margin-top: 12px;
}
.strength_progressive_bar25 {
  width: 25% !important;
}
.strength_progressive_bar50 {
  width: 50% !important;
}
.strength_progressive_bar75 {
  width: 75% !important;
}
.strength_progressive_bar100 {
  width: 100% !important;
}

.strength_progressive_bar_weak {
  background-color: #000000;
}

.strength_progressive_bar_strong {
  background-color: #000000;
}

.bar_bg {
  background-color: #d8dada;
  width: 100%;
}
.pwd-progress-bar-text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-family: "VzNHGeTX-reg11";
  color: #000000;
}
.pwdles_pass_main_heading,
.pwdless_pass_user_gap,
.pwdless_pass_forgot_text,
.back_icon_div {
  margin-bottom: 24px;
}
.pwdles_pass_sub_heading {
  margin-bottom: 8px;
}
.pwdless_pass_forgot_text {
  text-align: right;
}
.input_container {
  position: relative;
}
.toggle_text {
  position: absolute;
  right: 12px;
  top: 11.5px;
  border-bottom: 1px solid #000000;
  padding-bottom: 2px;
  cursor: pointer;
}
.pwdles_main_heading {
  margin-bottom: 12px;
}
.pwdles_sub_heading {
  margin-bottom: 24px;
}
.new_pwdless_btn_section {
  margin-top: 16px;
  width: 186px;
}
.new_pwdless_input_reset_field {
  margin-bottom: 8px;
}
.strength_label {
  margin-bottom: 24px;
}
.vbg-rsp-disabled-label {
  color: #d8dada !important;
  cursor: not-allowed;
  border-color: #d8dada !important;
}
.pwd-progress-bar-black{
  background-color: black;
  height: 2px;
}
.pwd-progress-bar {
  display: flex;
  align-items: center;
  /* background-color: white; */
  background-color: #D8DADA;
  /* border: black solid; */
  border-width: 2px;
  /* border-left: 40px solid black; */
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 16px
}
@media only screen and (max-width: 390px) {
.list_gap{
  margin-bottom: 16px;
}
}
</style>
  