<template>
  <div>
    <h1 class="new_pwdless_main_heading pwdles_main_heading">
      Let’s reset your password
    </h1>
    <form @submit.prevent="onCaptchaVerified('')">
      <div v-if="formInputs">
        <div class="new_pwdless_input_label">User ID</div>
        <input
          class="new_pwdless_input_field"
          id="userid"
          name="userid"
          @keydown.space.prevent
          ref="userIDRef"
          v-model="userEnteredUsername"
          type="text"
          :autocomplete="formInputs[0].autocomplete"
          v-bind:class="userValidationInputClass"
        />
      </div>

      <div v-if="formInputs">
        <div v-if="this.jCaptchaFlag">
          <div>
            <div class="captcha-blk">
              <div class="captcha-txt-blk">
                <img
                  id="captchaImg"
                  src=""
                  :class="[
                    getOAuthClass('oauth-flow-jcaptch'),
                    'captcha_img_fu',
                  ]"
                /><br />
              </div>
            </div>
            <a
              type="button"
              class="captcha_spacing new_pwdless_forgot_text_link"
              v-on:click="refreshCaptchaImgUrl()"
              v-on:keyup.space.enter="refreshCaptchaImgUrl()"
              name="refreshCaptcha"
              id="refreshCaptcha"
              tabindex="0"
            >
              Refresh Image
            </a>
          </div>
          <div>
            <label class="new_pwdless_input_label" for="captchaInput"
              >Type the characters</label
            >
            <input
              type="text"
              id="captchaInput"
              name="captchaInput"
              @keydown.space.prevent
              class="new_pwdless_input_field"
              v-model="captchaText"
            />
          </div>
        </div>
      </div>
      <div v-if="formInputs">
        <invisible-recaptcha
          v-if="this.captchaOn"
          :sitekey="forgotPwdCaptchaKey"
          theme="light"
          badge="bottomright"
          :callback="onCaptchaVerified"
          :class="getBtnClass()"
          :type="formInputs[1].type"
          >{{ formInputs[1].value }}
        </invisible-recaptcha>
        <div class="pwd_less_btn_container submit_btn_spacing">
          <div class="new_pwdless_btn_section">
            <button
              v-if="!this.captchaOn"
              :disabled="continueDisabled"
              @keyup.enter="onCaptchaVerified('')"
              class="new_pwdless_submit_btn"
              type="submit"
              :class="continueDisabled ? 'disabled' : ''"
              id="userid-continue-btn"
              name="userid-continue-btn"
              tabindex="0"
            >
              {{ formInputs[1].value }}
            </button>
          </div>
          <div class="new_pwdless_btn_section new_pwdless_btn_section_mobile">
            <button
              class="new_pwdless_submit_btn pwd_cancel_btn"
              @click="cancelRecovery()"
              style="margin-left: 0 !important"
              type="button"
              :style="formInputs[2].style"
              id="userid-cancel-btn"
              name="userid-cancel-btn"
              tabindex="0"
            >
              {{ formInputs[2].value }}
            </button>
          </div>
        </div>
        <!-- <div v-if="mybizzFlag" style="margin-top: 30px">
          <div>
            <h3>Need to contact admin?</h3>
            <p style="font-size: 16px; line-height: 22px; cursor: pointer">
              Contact your admin for additional support.
            </p>
            <h3>
              <a
                :href="contact_admin"
                style="
                  font-size: 16px;
                  letter-spacing: 0.5px;
                  cursor: pointer;
                  text-decoration: none;
                "
              >
                Contact admin
                <img style="width: 10px; height: 14px" :src="caret" />
              </a>
            </h3>
          </div>
        </div> -->
        <div style="margin-bottom: 80px"></div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import InvisibleRecaptcha from "vue-invisible-recaptcha";
import forgotFlowConfig from "@/common/assets/json/forgot_flow_config.json";
import "@/common/assets/styles/tooltip.css";
import caret_blk from "@/common/assets/styles/corp/images/CTA-caret_blk.png";
import translations from "@/common/assets/json/messages1.json";
import commonMixin from "@/common/mixin/commonMixins";
import commonErrorMixin from "../common/common-error-mixin";
import {
  setPageDetails,
  eventTracking,
  errorSet,
} from "../../../../../public/adobeTagging";
export default {
  name: "NewAMForgotPwdComponent",
  components: {
    "invisible-recaptcha": InvisibleRecaptcha,
  },
  mixins: [commonMixin, commonErrorMixin],
  data() {
    return {
      valid: false,
      contact_admin: iamvar_contact_admin,
      caret: caret_blk,
      fieldRules: [(v) => !!v || "Please fill out this field"],
      otpEnabled: true,
      userEnteredUsername: null,
      userValidationInputClass: "",
      captchaText: null,
      tooltipText: translations.en.message["enter_usrn_tooltip_msg"],
      captchaOn: true,
      jCaptchaFlag: true,
      submittedAlready: false,
      whichRealm: true,
      loaderGIF:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
    };
  },
  mounted() {
    this.changeContextBiocatch("forgot_password_user_id_page");
    window.scrollTo(0, 0);
    // get captcha image
    this.$nextTick(() => this.refreshCaptchaImgUrl());
    if (window.location.href.includes("business")) {
      this.readConfig("vbg_config");
    } else if (window.location.href.includes("consumer")) {
      this.readConfig("vcg_config");
    }
    if (this.urlOnMount != null || this.urlOnMount != undefined) {
      const hostUrl = this.urlOnMount;
      if (hostUrl.includes("/account/business/login/mobile")) {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      let iamvar_appCookie = getCookie("X-VZ-C_APP");
      if (iamvar_appCookie == "MVB") {
        this.whichRealm = false;
      }
    }
    if (iamvar_realm == "MOBILE") {
      let returnUrl = this.urlOnMount;
      this.updateUrlOnMount(returnUrl);
    }

    this.readFormInputs("userid_input_component");

    this.captchaOn = iamvar_enableCaptcha;

    this.jCaptchaFlag = iamvar_enableJCaptcha;
    //focusing first input field when user lands on this page
    this.$nextTick(() => this.$refs.userIDRef.focus());

    this.updateFFSQAEnabled(false);
    this.updateSQAValidated(false);
    this.updateVZCarrier(false);
    this.updateUserQuestions([]);
    this.updateSelectedQuestion();
    this.setOldSelectedDevice(null);
    if (this.username) {
      this.userEnteredUsername = this.username;
    }
  },
  methods: {
    ...mapActions("forgotflow", [
      "getUsernameCallback",
      "readConfig",
      "readFormInputs",
    ]),
    ...mapActions("cvsstepup", [
      "updateUsernameForStepup",
      "updateFlowType",
      "initializeStepup",
      "igCaptchaEndpoint",
      "updateRecaptchaResponse",
      "updateCaptchaText",
      "updateClearInputFields",
      "updateFFSQAEnabled",
      "updateSQAValidated",
      "updateVZCarrier",
      "updateSelectedQuestion",
      "updateUserQuestions",
      "setOldSelectedDevice",
    ]),
    ...mapActions("login", ["updateUrlOnMount"]),
    cancelRecovery() {
      this.$store.dispatch("cvsstepup/resetState");
      this.$store.dispatch("forgotflow/resetState");
      var returnUrl = this.getHostName(window.location.href);

      if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
        let iamvar_appCookie = getCookie("X-VZ-C_APP");
        if (iamvar_appCookie == "MVB") {
          window.location.href = iamvar_mvbLaunchUrl;
        }
      } else if (!this.urlOnMount) {
        window.location.href = this.sanitizeUrl(
          "https://" + returnUrl + "/account/business/login/unifiedlogin"
        );
      } else {
        window.location.href = this.urlOnMount;
      }

      // checking if user agent is vema then redirect user back to mobile login page
      if (this.userAgent && this.userAgent.toLowerCase() === "vema") {
        window.location.href = iamvar_vemaLaunchUrl;
      }
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.clearAllErrors();
      // submit only if required fields are filled
      if (!this.continueDisabled) {
        eventTracking("Password Reset Request Initiated", "");
        this.updateUsernameForStepup(this.userEnteredUsername.trim());
        this.updateCaptchaText(this.captchaText.trim());
        this.updateFlowType("forgot_password");
        // push to stepupRootComponent only if the username is valid (returned by the backend)

        this.updateRecaptchaResponse(recaptchaToken);
        this.igCaptchaEndpoint({ challengeMethod: "SQA" });
      }
    },
    getBtnClass() {
      var btnClass = this.formInputs[1].class;
      if (
        !this.userEnteredUsername ||
        (!this.captchaText && this.jCaptchaFlag)
      ) {
        btnClass += " inactive";
      }
      return btnClass;
    },
    // getCaptchaImgUrl() {
    //   var captchaImgHref = this.getHostName(window.location.href);
    //   let srcURL = this.sanitizeUrl("https://" + captchaImgHref + "/ifgservices/vbg/captcha");
    //   return srcURL;
    // },
    refreshCaptchaImgUrl() {
      const captchaImgHref = this.getHostName(window.location.href);
      document.getElementById("captchaImg").src = this.sanitizeUrl(
        "https://" +
          captchaImgHref +
          "/ifgservices/vbg/captcha?cb=" +
          Math.random()
      );
    },
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (
        match != null &&
        match.length > 2 &&
        typeof match[2] === "string" &&
        match[2].length > 0
      ) {
        return match[2];
      } else {
        return null;
      }
    },
    getOAuthClass(className = "oauth-flow", subClass = "") {
      return className + " " + subClass;
    },
  },
  computed: {
    ...mapGetters("forgotflow", [
      "forgotPwdCaptchaKey",
      "forgotFlConfig",
      "formInputs",
    ]),
    ...mapGetters("cvsstepup", [
      "userValidationError",
      "userValidationContactAdminError",
      "currentStep",
      "cannotProceedRecovery",
      "flowType",
      "urlOnMount",
      "fpAlerts",
      "submitLoader",
      "clearInputFields",
      "username",
    ]),
    ...mapGetters("login", ["mybizzFlag", "userAgent","gotoUrl"]),
    formData: {
      get: function () {
        return this.$store.getters["forgotflow/formData"];
      },
      set: function (newVal) {
        this.$store.dispatch("forgotflow/updateFormData", newVal);
      },
    },
    userVerified: {
      get: function () {
        return this.$store.getters["forgotflow/userVerified"];
      },
      set: function (newVal) {
        this.$store.dispatch("forgotflow/updateUserVerified", newVal);
      },
    },
    continueDisabled() {
      return (
        !this.userEnteredUsername ||
        (!this.captchaText && this.jCaptchaFlag) ||
        this.submitLoader
      );
    },
  },

  watch: {
    clearInputFields: function (newVal) {
      console.log("watch value", newVal);
      if (newVal) {
        this.userEnteredUsername = "";
        this.captchaText = null;
        this.updateClearInputFields(!newVal);
      }
    },
    userValidationError: function (newVal) {
      if (newVal) {
        this.userValidationInputClass = "vz-error";
        this.refreshCaptchaImgUrl();
        this.captchaText = null;
        this.submittedAlready = false;
      } else {
        this.userValidationInputClass = "";
      }
    },
    userValidationContactAdminError: function (newVal) {
      if (newVal) {
        this.userValidationInputClass = "vz-error";
        this.refreshCaptchaImgUrl();
        this.captchaText = null;
        this.submittedAlready = false;
      } else {
        this.userValidationInputClass = "";
      }
    },
    currentStep: function (newVal) {
      if (newVal === "FLOW_INITIALIZED" && !this.cannotProceedRecovery) {
        if (pwd_less_new_login) {
          if (this.gotoUrl) {
            this.$router.push({
              path: "/login/stepup/otpdelivery",
              query: { goto: this.gotoUrl },
            });
          } else this.$router.push({ path: "/login/stepup/otpdelivery" });
        } else this.$router.push({ name: "FFStepupRootComponent" });
      }
    },
    fpAlerts: function (newVal) {
      if (newVal.length) {
        this.refreshCaptchaImgUrl();
        this.captchaText = null;
        this.submittedAlready = false;
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>
<style scoped>
.pwdles_main_heading {
  margin-bottom: 24px;
}
.pwdles_sub_heading {
  margin-bottom: 32px;
}
.new_pwdless_btn_section {
  margin-top: 16px;
  width: 186px;
}
</style>
