<template>
  <div class="back_icon_div" @click="goBack()">
    <a><img src="@/common/assets/images/backIcon.png" alt="back_icon" /> </a>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import commonMixin from "@/common/mixin/commonMixins";
import commonErrorMixin from "./common-error-mixin";
export default {
  name: "navigationHistory",
  mixins: [commonMixin, commonErrorMixin],
  computed: {
    ...mapGetters("login", ["getNavHistory", "getCurrentComponent", "gotoUrl"]),
    ...mapGetters("cvsstepup", ["flowType", "userIdFlow"]),
  },
  methods: {
    ...mapMutations("login", ["unsetNavHistory"]),
    ...mapActions('cvsstepup', ['updateUserIdFlow',]),
    goBack() {
      if(this.flowType == 'forgot_username' && this.getCurrentComponent.name == "NewAMForgotUsrnmComponent" && (this.userIdFlow == 'SIMPLE' || this.userIdFlow == 'COMPLEX')) {
        this.updateUserIdFlow('BASIC')
        this.$store.dispatch("cvsstepup/resetState");
        this.$store.dispatch("forgotflow/resetState");
        return
      } else {
      this.clearAllErrors();
      // Redirecting to login page when user coming from reset password cancel button
      if (
        this.getCurrentComponent.name == "NewAMForgotPwdComponent" &&
        JSON.stringify(this.getNavHistory).includes(
          "NewAMResetPasswordComponent"
        )
      ) {
        this.$emit("redirectToLogin");
        return;
      }

      // Cancel the polling call if we click the back button
      if (this.getCurrentComponent.name == "NewOtpValidate")
        this.$store.dispatch("cvsstepup/cancelVerification");

      this.unsetNavHistory();
      // In forgot password flow if he entered in sqa page then back button way we are navigating to DeliveryOtp component
      if (
        this.flowType == "forgot_password" &&
        this.getCurrentComponent.name == "NewSqaComponent"
      )
        this.unsetNavHistory();
        const gotoURL = this.gotoUrl;
        if (gotoURL) {
          this.$router.replace({
            path: this.getCurrentComponent.path,
            query: { goto: gotoURL },
          });
        } else {
          this.$router.replace({ path: this.getCurrentComponent.path });
        }
      }
    },
    },
  };
</script>
<style>
.back_icon_div {
  margin-bottom: 24px;
}
</style>